import React from "react"
import { StyledContactMan } from "../styles/pages/contact"
import {
  Instagram,
  Linkedin,
  Tiktok,
  Whatsapp,
} from "styled-icons/boxicons-logos"
import axios from "axios"
import { initializeApp } from "firebase/app"
import { getDoc, doc, getFirestore } from "firebase/firestore"
import { FIREBASE_KEYS } from "../system/environment"
import { NotificationManager } from "react-notifications"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"
import Layout from "../components/layout"

const Contact = () => {
  const [countries, setCountries] = React.useState([])
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    phoneCode: 52,
    body: "",
  })
  const appMain = initializeApp(FIREBASE_KEYS)
  const dbMain = getFirestore(appMain)

  const onSubmit = e => {
    e.preventDefault()
    const data = { ...formData }
    const email = ["esther.hernandez@lapieza.io", "paz.zarza@lapieza.io"]
    const payload = {
      email,
      data,
      templateID: "d-98dd7f36b7db44869b34a82d1d411a60",
    }

    sendEmail(payload)
      .then(() => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          phoneCode: 52,
          body: "",
        })
        NotificationManager.success("Se ha enviado correctamente.", null, 10000)
      })
      .catch(() => {
        NotificationManager.error(
          "Ocurriió un error, por favor intenta más tarde.",
          null,
          10000
        )
      })
  }

  async function getCodes() {
    try {
      const response = (
        await getDoc(doc(dbMain, "API_data/Profile/countries", "data"))
      ).data().list
      setCountries(response)
    } catch (error) {
      setCountries([
        {
          nombre: "México",
          name: "Mexico",
          nom: "Mexique",
          iso2: "MX",
          iso3: "MEX",
          phoneCode: 52,
        },
      ])
    }
  }

  const sendEmail = async (data = {}) => {
    await axios.request({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: "https://us-central1-lapieza-production.cloudfunctions.net/onlySendEmailSendgrid",
      method: "POST",
      data,
      crossDomain: true,
    })
  }

  const onChange = e => {
    const { name, value } = e.target
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode
  }

  React.useEffect(() => {
    getCodes()
  }, [])

  return (
    <Layout>
      <StyledContactMan>
        <NotificationContainer />
        <section id="containerDescription">
          <h2> Contacto </h2>
          <h1>¿Tienes alguna duda?</h1>
          <h3>Estaremos encantados de responder tus preguntas.</h3>
          <h4>Encuentranos en:</h4>
          <ul className="horizontal-list">
            <li>
              <a
                href="https://bit.ly/49tZ6Ti"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp size="32" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/academybylapieza/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram size="32" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/academy-by-lapieza/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size="32" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@academybylapieza"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tiktok size="32" />
              </a>
            </li>
          </ul>
        </section>
        <form id="formContainer" onSubmit={onSubmit}>
          <article>
            <label> Nombre </label>
            <input
              placeholder="Nombre"
              name="name"
              onChange={onChange}
              value={formData.name}
              required
            />
            <label> Correo electrónico </label>
            <input
              placeholder="Correo electrónico"
              name="email"
              type="email"
              onChange={onChange}
              value={formData.email}
              required
            />
            <label> Teléfono </label>
            <div>
              <select onChange={onChange} value={52} name="phoneCode">
                {countries.map(country => (
                  <option value={country.phoneCode}>
                    {" "}
                    {countryToFlag(country.iso2)} +{country.phoneCode}
                  </option>
                ))}
              </select>
              <input
                placeholder="Teléfono"
                name="phone"
                onChange={onChange}
                value={formData.phone}
                id="number"
                required
                type="number"
                min="1"
                pattern="[0-9]{8}"
                onKeyDown={evt =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
            </div>
            <label> ¿Cómo te podemos ayudar? </label>
            <textarea
              placeholder="Hola ...."
              onChange={onChange}
              name="body"
              value={formData.body}
              required
            />
            <button type="submit"> Enviar </button>
          </article>
        </form>
      </StyledContactMan>
    </Layout>
  )
}

export default Contact
