import styled from "styled-components"
import palette from "../styled-palette"

export const StyledContactMan = styled.main`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 40% 60%;
  .horizontal-list {
    display: flex;
    justify-content: start;
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }

    li {
      position: relative;
      display: flex;
      margin-right: 16px;
      overflow: hidden;
      margin-top: 8px;
      color: ${palette.colorWhite};
      font-size: 14px;
    }
    svg {
      color: ${palette.letterColor};
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin-top: 16px;
  }
  #containerDescription {
    width: 70%;
    height: 50%;
    justify-self: center;
    align-self: center;
    h2 {
      font-family: ${palette.fontSofiaRegular};
      color: ${palette.letterColor};
      letter-spacing: 0.5px;
      font-size: 1.7em;
      text-align: start;
    }
    h1 {
      font-family: ${palette.fontCooperMedium};
      letter-spacing: 0.5px;
      font-size: 2.9em;
      text-align: start;
    }
    h3 {
      width: 80%;
      font-family: ${palette.fontSofiaProLight};
      letter-spacing: 0.5px;
      font-size: 1.8em;
      text-align: start;
      line-height: 25px;
    }
    h4 {
      font-family: ${palette.fontSofiaProLight};
      letter-spacing: 0.5px;
      font-size: 1.5em;
      padding-top: 20px;
      text-align: start;
      line-height: 25px;
    }
  }
  #formContainer {
    height: auto;
    width: 70%;
    padding: 20px;
    margin: 40px 0;
    border-radius: 20px;
    box-shadow: ${palette.boxShadowUp};
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    article {
      width: 80%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      label {
        font-family: ${palette.fontSofiaRegular};
        font-size: 1.8em;
      }
      input {
        padding: 15px 10px;
        border: none;
        font-family: ${palette.fontSofiaRegular};
        font-size: 1.8em;
        border-radius: 25em;
        background-color: ${palette.colorInput};
      }
      select {
        padding: 15px 20px;
        border: none;
        font-family: ${palette.fontSofiaRegular};
        font-size: 1.5em;
        border-radius: 25em;
        background-color: ${palette.colorInput};
      }
      textarea {
        padding: 15px 10px;
        max-width: 100%;
        min-width: 100%;
        min-height: 50px;
        max-height: 50px;
        border: none;
        font-family: ${palette.fontSofiaRegular};
        font-size: 1.3em;
        border-radius: 10px;
        background-color: ${palette.colorInput};
      }
      div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
        width: 100%;
        justify-content: space-between;
        input {
          width: calc(80% - 40px);
          padding: 15px 20px;
          border: none;
          font-family: ${palette.fontSofiaRegular};
          font-size: 1.5em;
          border-radius: 25em;
          background-color: ${palette.colorInput};
        }
      }
      button {
        margin-top: 20px;
        padding: 10px 80px;
        border-radius: 25em;
        border: none;
        box-shadow: none;
        background-color: ${palette.colorPurple};
        color: ${palette.colorWhite};
        font-family: ${palette.fontSofiaRegular};
        font-size: 1.5em;
        letter-spacing: 0.5px;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 850px) {
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 50px;
    #containerDescription {
      width: 90%;
      margin: 30px auto;
      h2,
      h1,
      h3,
      h4 {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    .horizontal-list {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    #formContainer {
      width: 80%;
      margin: auto;
      article {
        width: 90%;
        div {
          gap: 10px;
          input {
            width: calc(70% - 50px);
          }
        }
      }
    }
  }
  @media (max-width: 1025px)  {
    height: 90vh;
    padding: 40px 0;
    #containerDescription {
      width: 80%;
      height: auto;
      justify-self: center;
      align-self: center;
    }
    #formContainer {
      height: auto;
      article {
        width: 90%;
        div {
          gap: 10px;
          input {
            width: calc(70% - 50px);
          }
        }
      }
    }
  }
  @media (max-width: 920px) {
    height: 60vh;
    #containerDescription {
      width: 80%;
      margin: auto;
      h2,
      h1,
      h3,
      h4 {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .horizontal-list {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    #formContainer {
      margin: auto;
      height: auto;
      padding: 50px 20px;
      width: 80%;
      article {
        width: 90%;
        div {
          gap: 10px;
          input {
            width: calc(70% - 50px);
          }
        }
      }
    }
  }
  @media (max-width: 430px) {
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    #containerDescription {
      width: 90%;
      margin: auto;
      h2,
      h1,
      h3,
      h4 {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    .horizontal-list {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    #formContainer {
      width: 80%;
      margin: auto;
      article {
        width: 90%;
        div {
          gap: 10px;
          input {
            width: calc(70% - 50px);
          }
        }
      }
    }
  }
  
  
`;
